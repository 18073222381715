import React, { useState, useEffect } from "react";

const Input = ({
  label,
  name,
  slug,
  className,
  icon,
  onChange,
  type,
  value,
  placeholder,
  disabled,
  error,
  darmo,
  actionStart,
  actionEnd,
  onEnterPressed,
  min,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const passwordToggle = !passwordVisible ? (
    <i
      className={`h-full ${
        darmo ? "text-gray-400" : "text-white"
      } far fa-eye-slash`}
    />
  ) : (
    <i
      className={`h-full ${darmo ? "text-gray-400" : "text-white"} far fa-eye`}
    />
  );

  useEffect(() => {
    if (name === "cpassword") {
      const passwordInput = document.getElementsByName("password")[0];

      if (!value?.trim()) {
        setValidationMessage(
          !passwordInput.value
            ? "Please enter password first"
            : "confirm password is required"
        );
      } else if (value !== passwordInput.value) {
        setValidationMessage("Passwords do not match");
      } else {
        setValidationMessage("");
      }
    }
  }, [name, value]);

  return (
    <div className={className}>
      <label
        className={`block font-bold title ${
          darmo ? "text-warmgray-900" : "text-white"
        }`}
      >
        {label}
      </label>

      <div
        className={
          "flex flex-row w-full focus:outline-none" +
          (error ? " border-red-500" : " border-gray-700")
        }
      >
        <div className="flex flex-row items-center w-full">
          {slug && (
            <div className="bg-warmgray-200 dark:bg-slate-800 text-gray-900 dark:text-white dark:border dark:border-white p-2.5 h-[40px] rounded-l-md">
              <span className="self-center">{slug}</span>
            </div>
          )}

          <div className="relative w-full">
            <input
              className={`${
                darmo
                  ? "text-gray-900 placeholder:text-gray-400 bg-white disabled:bg-warmgray-200"
                  : "text-white placeholder:text-gray-300 bg-slate-800 dark:disabled:bg-darkGray"
              } block w-full ${slug ? "rounded-r-md" : "rounded-md"} ${
                type === "password" && "pr-8"
              } border-0 p-2 ring-1 ring-inset ring-gray-100 focus:ring-gray-100 sm:text-sm sm:leading-6 pr-2
              }`}
              name={name}
              type={passwordVisible ? "text" : type}
              value={value}
              min={min}
              placeholder={placeholder}
              onChange={(event) => onChange(event)}
              onKeyPress={(e) => {
                if (e.code === "Enter" && onEnterPressed) {
                  onEnterPressed();
                }
              }}
              disabled={disabled}
              autoComplete={type === "password" ? "off" : ""}
            />

            {type === "password" && (
              <div
                className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordToggle}
              </div>
            )}
          </div>

          {actionEnd}
        </div>
      </div>
      {validationMessage && (
        <div className="text-red-400 text-xs font-bold my-0.5">
          {validationMessage}
        </div>
      )}
      {error ? (
        <div className="text-red-400 text-xs font-bold my-0.5">{error}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
