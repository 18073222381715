import React from "react";
import { Menu } from "@headlessui/react";

const MoreHorizontalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="h-5 w-5"
  >
    <circle cx="12" cy="12" r="1" />
    <circle cx="19" cy="12" r="1" />
    <circle cx="5" cy="12" r="1" />
  </svg>
);

const MoreVerticalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="h-5 w-5"
  >
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </svg>
);

const IconSelector = ({
  menus,
  orientation = "horizontal",
  color = "gray",
}) => {
  // Determine which icon to use based on orientation
  const Icon =
    orientation === "horizontal" ? MoreHorizontalIcon : MoreVerticalIcon;

  const colorClasses = {
    gray: "text-gray-400 hover:text-gray-600 dark:hover:text-gray-300",
    white: "text-white hover:text-gray-200 dark:hover:text-gray-100",
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={`flex items-center rounded-full ${colorClasses[color]} 
          focus:outline-none focus:ring-2 dark:focus:ring-0 
          focus:ring-indigo-500 focus:ring-offset-2 
          focus:ring-offset-gray-100`}
      >
        <Icon aria-hidden="true" />
      </Menu.Button>

      <Menu.Items
        className="absolute right-0 z-10 mt-2 w-auto whitespace-nowrap 
          origin-top-right rounded-md bg-white shadow-lg ring-1 
          ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="py-1">
          {menus.map((menu, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <button
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    active ? "bg-gray-300 text-gray-900" : "text-gray-900"
                  } ${menu.disabled ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={menu.action}
                  disabled={menu.disabled}
                >
                  <div className="flex items-center gap-2">
                    {menu.icon && (
                      <menu.icon
                        className={`h-4 w-4 ${
                          menu.disabled ? "text-gray-400" : "text-gray-500"
                        }`}
                      />
                    )}
                    {menu.label}
                  </div>
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default IconSelector;
